<template>
  <div class="h-panel cf">
    <div class="left-container">
      <div class="h-title">
        <span class="title"><i class="icon icon_hangqing"></i>市场行情</span>
        <span class="more fr" @click="sons()">更多</span>
      </div>
      <div class="left">
        <div class="leftTop">
          <div class="flex marketList" v-for="(item,idx) in pricotype" :key="idx">
            <div class="shengzhu flex" v-if="idx!=3">
              <div>
                FT
              </div>
              <div>
                <p>{{item.productName.slice(0,2)}}</p>
                <p class="color999">{{item.productName.slice(2)}}</p>
              </div>
            </div>
            <div class="shengzhu flex" v-if="idx==3">
              <div>
                FT
              </div>
              <div>
                <p>{{pricotype1.productName}}</p>
                <p class="color999">(比率)</p>
              </div>
            </div>
            <div class="myChart" :style="{ width: '65%', height: '70px' }"></div>
            <div class="marketListBottom"  v-if="idx!=3">
              <p class="tright green" v-if="item.trend=='same'">{{item.price}}</p>
              <p class="tright red" v-if="item.trend=='up'">{{item.price}}</p>
              <p class="tright green" v-if="item.trend=='down'">{{item.price}}</p>
              <div class="color999 width83" v-if="item.trend=='same'">--<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1">元/吨</span>
              </div>
              <div class="color999 width83" v-if="item.trend=='up'">↑{{(item.diffYesterday)}}<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1">元/吨</span>
              </div>
              <div class="color999 width83" v-if="item.trend=='down'">↓{{-(item.diffYesterday)}}<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1">元/吨</span>
              </div>
            </div>
            <div class="marketListBottom" v-if="idx==3">
              <p class="tright green" v-if="item.trend=='same'">{{item.price}}&nbsp;:&nbsp;1</p>
              <p class="tright red" v-if="item.trend=='up'">{{item.price}}&nbsp;:&nbsp;1</p>
              <p class="tright green" v-if="item.trend=='down'">{{item.price}}&nbsp;:&nbsp;1</p>
              <div class="color999 textCenter width83" v-if="item.trend=='same'">--<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1"></span>
              </div>
              <div class="color999 textCenter width83" v-if="item.trend=='up'">↑{{(item.diffYesterday)}}<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1"></span>
              </div>
              <div class="color999 textCenter width83" v-if="item.trend=='down'">↓{{-(item.diffYesterday)}}<span v-if="item.productType==1">元/公斤</span><span v-if="item.productType!=1"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <ul>
          <li v-for="(item,idx) in pigpricoHot" :key="idx" @click="son(item)">
            <a href="javasript:;">[猪价]</a>{{item.title}}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapGetters } from 'vuex'
  import { getQuotes, getQuotesList, getdetail, getpricotype } from '@/api/home2.js'
  import { getWebTitle } from '../../../api/home1'
  import {constCommon} from '@/consts/common'

  export default {
    name: 'MarketLeft',

    data () {
      return {
        address : constCommon.shortName ,
        pigpricoList: [],
        pigpricoHot: [],
        pricotype:'',
        pricotype1:'',
        list:[],
        arr:'',
        data:[],
        ratio:''
      }
    },
    methods: {
      drawLine () {
        var echarts = require("echarts");
        var myechart = document.getElementsByClassName('myChart');
        for(var i=0;i<myechart.length;i++){
          var myChart = echarts.init(myechart[i]);
          myChart.setOption({
              tooltip: {
                  trigger: 'axis',
              },
              xAxis: {
                  type: 'category',
                  boundaryGap: true,
                  show:false,
                  data:this.data,
              },
              yAxis: {
                  type: 'value',
                  min:this.fn(this.arr[i]),
                  splitLine:{
                    show:false//不显示网格线
                  },
                  axisLabel: {
                    show: false,//不显示坐标轴上的文字
                  },
                  axisLine: {
                    show: false,//不显示坐标轴线
                  },
                  axisTick:{
                    show:false//不显示坐标轴刻度线
                  },
              },
              series: [{
                  data: this.arr[i],
                  type: 'line',
                  itemStyle: {
                    color: '#ec5751'
                  },
                  areaStyle: {
                    color: '#fae2e3'
                  },
                  symbol: 'none',
              }]
          })
        }
      },
      getpricoList () {
        getdetail({
         id:'1386281981576626178'
        }).then((res) => {
          getQuotesList({
            columnId:res.data.id,
            isRecommend:0
          }).then((res) => {
            this.pigpricoHot=res.data.records
          })
          // getQuotesList({
          //   columnId:res.data.id
          // }).then((res) => {
          //   this.pigpricoList=res.data.records
          // })
        })
      },
       //获取网站名称
    getWebTitle2() {
        getWebTitle().then((res) => {
                  if (!res.data.records[0].status) {
document.title = res.data.records[0].websiteName
        }else{
          document.title = ''
        }

      })
    },
      son(item){
        let routerJump = this.$router.resolve({
          path: '/domesticDetails',
          query: {
            titleId:item.id,
            // columid:'1386281981576626178',
            // columnNameId:'1386281981576626178',
          }
        })
        window.open(routerJump.href, '_blank');
      },
      sons(){
        this.$router.push({
          path: '/pigprico',
          query: {
            columid:'1386281981576626178',
            // columnNameId:'1386281981576626178',
          }
        })
      },
      getpricotypes(address){
        getpricotype({address}).then((res) => {
          this.pricotype1=res.data
          this.pricotype=res.data
          this.pricotype1=this.pricotype[res.data.length-1]
          // this.pricotype.splice(res.data.length-1,1)
          // this.$nextTick(()=>{
          //   this.setChangeWidth()
          // });
          for(let i=0;i<res.data.length;i++){
            var n = 0
            for(let j=0;j<(res.data[i].priceList.length>7?7:res.data[i].priceList.length);j++){
              var timeString = res.data[i].priceList[j].releaseTime
              var num = timeString.substring(8,11) - this.data[n].substring(8,11)
              if (num != 0){
                for (let m = 0;m<num;m++){
                  this.list.push(0)
                  n = n + 1
                }
                n = n + 1
                this.list.push(res.data[i].priceList[j].price)
              }else{
                this.list.push(res.data[i].priceList[j].price)
                n = n + 1
              }
            }
          }
          let arr=[]
          for(var i=0;i<res.data.length;i++){
            let obj={}
            obj.prico=this.list.slice(((this.list.length/res.data.length)*i==0?0:((this.list.length/res.data.length)*i)),((this.list.length/res.data.length)*(i+1)))
            arr.push(obj.prico)
          }
          this.arr=arr
        })
      },
      dataday(){
       var t = moment()
        for(var i=0;i<7;i++){
          var agot = t.subtract(i>=1?1:i, 'days').format('YYYY-MM-DD')
          this.data.push(agot)
          this.data.sort()
        }
      },
      fn(arr){
        var tmp = []
        for (let i = 0;i<arr.length;i++){
          if (arr[i] != 0){
            tmp.push(arr[i])
          }
        }
        let newPrice=Math.max(...tmp)-Math.min(...tmp)
        return Math.min(...tmp)-newPrice;
      },
      setChangeWidth(){
        // if (this.pricotype1.price) {
        // let newArr=this.pricotype1.price.split(':')
        // let num1 = newArr[0]*1
        // let num2 = newArr[1]*1
        // let num3 = num1+num2
        // let num4 = num1/num3*143+'px'
        // document.getElementById("sonWidth").style.width=num4;
        // }
      }
    },
    mounted () {
      this.dataday()
      this.getpricoList()
      this.getpricotypes(this.address)
      // this.getWebTitle2()
      // this.setChangeWidth()
      setTimeout(()=>{
        this.drawLine()
      },1000)
    },
    computed: {
      ...mapGetters({
        columnData:'getColumnData'
      })
    },
    watch: {
      columnData (val) {
        if(JSON.stringify(val) !== '{}') {
          const columId=this.columnData.information.id
        }
      },
      immediate: true
    }
  }
</script>

<style scoped>
  .icon_hangqing {
    background-image: url("~@/assets/img/icon_hangqing.png");
  }
  .aitems{
    align-items: center;
  }
  .left-container .h-title{
    margin-bottom: 30px;
  }
  .left-container .left{
    width: 58%;
    font-size: 14px;
  }
  .left-container .leftTop{
    width: 100%;
  }
  .left-container .leftTop>div{
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .left-container .shengzhu div:nth-child(1){
    background-color: #51d5c3;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-right: 5px;
  }
  .left-container .shengzhu{
    width:105px;
    justify-content: start;
    padding-top: 15px;
  }
  .color999{
    color: #cacaca;
  }
  .left-container .marketListBottom div{
    text-align: right;
  }
  .left-container .marketListBottom p{
    margin-bottom: 5px;
  }
  .left-container .right{
    width: 36%;
    font-size: 16px;
  }
  .left-container .right li{
    position: relative;
    margin:15px 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .left-container .right li::before{
    content: "·";
    position: absolute;
    color: #c6c6c6;
    font-size: 30px;
    left: -10px;
    top: -10px;
  }
  .left-container .right li a{
    color: #c6c6c6;
    margin-right: 10px;
  }
  .right-container{
    height: 100%;
    background-color: #f1f1f1;
    padding: 20px 20px;
  }
  .right-container h1{
    font-weight: bold;
    font-size: 16px;
    margin: 0 0 20px;
    color: #7a7a7a;
  }
  .right-container li{
    margin: 10px 0;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .right-container li span{
    border: 1px solid #bababa;
    font-weight: bold;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin-right: 20px;
    color: #626262;
  }
  .right-container .active{
    color: #fff;
    background-color: #51d5c3;
    border: none;
  }
  li:hover{
    color: #51d5c3;
  }
  .red{
    background-color: #e74748;
  }
  .green{
    background-color: #4a9e28;
  }
  .tright{
    color: #fff;
    width: 80px;
    margin-top: 8px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 5px;
  }
  .progress-content{
    height: 45px;
    width: 100%;
    border-radius: 4px;
    background: #fae2e3;
  }
  .progress-content div{
    height: 100%;
    border-radius: 4px;
    background: #ec5751;
  }
  .textCenter{
    text-align: center !important;
  }
  .width83{
    width: 83px;
  }
</style>
