<template>
  <div>
      <informationLeft></informationLeft>
      <marketLeft></marketLeft>
      <policyLeft></policyLeft>
      <businessLeft></businessLeft>
  </div>
</template>

<script>
  import InformationLeft from './informationLeft'
  import MarketLeft from './marketLeft'
  import PolicyLeft from './policyLeft'
  import BusinessLeft from './businessLeft'
export default {
    components: {
      InformationLeft,
      MarketLeft,
      PolicyLeft,
      BusinessLeft
    },
}
</script>

<style>

</style>