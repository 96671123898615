<template>
  <div class="h-panel cf">
    <div class="left-container">
      <div class="h-title">
        <span class="title"><i class="icon icon_zixun"></i>农业资讯</span>
         <a class="more fr" @click="changeNav()">更多</a>
      </div>
      <div class="cf mt20 part1">
        <div class="info-left" v-if="quotesList.length">
          <div class="img-area" @click="son(quotesList[0])">
<!--            <img :src="quotesList[0].icon" alt="">-->
            <div class="img_bg img01" :style="{backgroundImage: 'url(' +quotesList[0].icon + ') '}" v-if="!quotesShow1"></div>
            <div class="img_bg img01 contentImg" v-if="quotesShow1"></div>
            <p class="desc">{{quotesList[0].title}}</p>
          </div>
          <div class="bottom">
            <p v-for="(item, index) in quotesList.slice(1)" :key="index" @click="son(item)">
              <router-link to="/" class="link"><span class="mark"></span>{{item.title}}</router-link>
            </p>
          </div>
        </div>
        <div class="info-right">
          <ul class="info-right-list">
            <li style="overflow: hidden;text-overflow:ellipsis;white-space: nowrap;" v-for="(item, index) in realNews.slice(0,10)" :key="index" @click="son(item)">{{item.title}}</li>
          </ul>
        </div>
      </div><!--part1-->
      <div class="cf mt10 part2" v-if="realNews.length">
        <div class="left">
          <div class="img-area" @click="son(realNews[13])">
<!--            <img :src="quotesList[3].icon" alt="">-->
            <div class="img_bg img02" :style="{backgroundImage: 'url(' +realNews[13].icon + ') '}" v-if="!realNews1"></div>
            <div class="img_bg img02 contentImg" v-if="quotesShow2"></div>
            <span class="desc">{{realNews[13].title}}</span>
          </div>

        </div>
        <div class="right">
          <div class="img-area" @click="son(realNews[14])">
            <div class="img_bg img03" :style="{backgroundImage: 'url(' + realNews[14].icon + ') '}" v-if="!realNews1"></div>
            <span class="desc">{{realNews[14].title}}</span>
          </div>
          <div class="img-area" @click="son(realNews[15])">
            <div class="img_bg img03" :style="{backgroundImage: 'url(' + realNews[15].icon + ') '}" v-if="!realNews1"></div>
            <!-- <div class="img_bg img03 contentImg" v-if="quotesShow4"></div> -->
            <span class="desc">{{realNews[15].title}}</span>
          </div>

      </div>
      </div>
      </div>
  </div>
</template>

<script>
  import { getdetail, getQuotesList, getNews, getQuotes, getdynamicList, getpricotype,getNew } from '@/api/home'
  import { mapGetters } from 'vuex'
  export default {
    name: 'InformationLeft',
    data () {
      return {
        quotesList: [],
        quotesShow1: false,
        realNews1 : false,
        realNews2: false,
        realNews3:false,
        realNews: [],
        hotNewsAll: [],
        hotNews: [],
        tabIndex: 0,
        columid:'',
        pathname:'',
        paths:'',
        pigpriviewve:'',
        id:'',
        name:'',
        pathrouter:'',
        colname:''
      }
    },
    created () {

    },
    computed: {
      ...mapGetters({
        columnData: 'getColumnData'
      })
    },
    mounted () {
      // 不注释下面的方法，该方法获取农业资讯
      this.getcloumid()
      // this.getreview()
      // this.getnewsData()
      // this.getHotNews()
    },
    methods: {
        getcloumid() {
          getQuotes({
            parentId:0
          }).then((res)=>{
            for(let i=0;i<res.data.length;i++){
              if(res.data[i].id=="1393021449385963522"){
                this.colname=res.data[i].columnName
              }
            }
            this.columid = res.data[0].id

            this.pathname = res.data[0].columnName
            this.paths = res.data[0].accessPath
            getQuotesList({
              columnId:res.data[0].id,
              size:30
            }).then((res) => {
              const records = res.data.records
              if (records.length > 7) {
                this.quotesList = records.slice(0, 3)
                this.realNews = records.slice(3)
              }  else {
                this.quotesList = records
              }
              if (this.quotesList[0].icon == '' || this.quotesList[0].icon == null || this.quotesList[0].icon == undefined) {
                this.quotesShow1 = true
              }
              if (this.realNews[0].icon == '' || this.quotesList[3].icon == null || this.quotesList[3].icon == undefined) {
                this.realNews1 = true
              }
              if (this.realNews[1].icon == '' || this.quotesList[4].icon == null || this.quotesList[4].icon == undefined) {
                this.realNews2 = true
              }
              if (this.realNews[2].icon == '' || this.quotesList[5].icon == null || this.quotesList[5].icon == undefined) {
                this.realNews3 = true
              }
            })
          })
        },
      // 获取专题
      getQuotesListData () {

      },
      // 获取新闻
      getnewsData () {
        getQuotes({
          parentId:0
        }).then((res)=>{
          getNew({
           current:1,
           size:16,
           columnId:this.columnData.information.id
          }).then((res) => {
            this.realNews = res.data.records
          })
        })
      },
      // 获取关注热点
      getHotNews () {
        getQuotes({
          parentId:0
        }).then((res)=>{
          getNews({
            type: 2
          }).then((res) => {
            this.hotNewsAll = res.data.records
            this.hotNews = res.data.records.slice(0, 7)
          })
        })
      },
      // 点击热点Tab
      toggleTab (index) {
        this.tabIndex = index
        if (index === 0) { // 热点新闻
          this.hotNews = this.hotNewsAll.slice(0, 7)
        } else {
          this.hotNews = this.hotNewsAll.slice(7, 14)
        }
      },
      getreview(){
        getdynamicList({
          columnName:this.colname
        }).then((res) => {
          this.id=res.data.records[0].id
          this.name=res.data.records[0].columnName
          this.pathrouter=res.data.records[0].accessPath
          getQuotesList({
            columId:res.data.records[0].id
          }).then((res) => {
            this.pigpriviewve=res.data.records
          })
        })
      },
      son(item){
        getdetail({
          id:item.columnId
        }).then((res) => {
          let routerJump = this.$router.resolve({
            path: '/domesticDetails',
            query: {
              // columid: this.columid,
              titleId:item.id,
              // columnNameId:res.data.id,
            }
          })
          window.open(routerJump.href, '_blank');
        })
      },
      changeNav(){
        this.$router.push({
          path: this.paths,
          query: {
            columid: this.columid,
          }
        })
      },
      jump(){
        this.$router.push({
          path: '/agricuunforThree',
          query: {
            columnNameId: this.id,
            columid: this.id,
          }
        })
      },
      son1(item){
        let routerJump = this.$router.resolve({
          path: '/domesticDetails',
          query: {
            // columid: this.id,
            titleId:item.id,
            // columnNameId:this.id,
          }
        })
        window.open(routerJump.href, '_blank');
      }
    },
    watch: {
      columnData (val) { // 因接口加载有延时，所以需要监听
        if (JSON.stringify(val) !== '{}') { // 如果监听到栏目数据

        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .icon_zixun {
    background-image: url("~@/assets/img/icon_zixun.png");
  }
  .tab-btn.icon_tab01 span:before {
    background-image: url("~@/assets/img/icon_tabon01.png");
  }
  .mt10{
    margin-top: 10px;
  }
  .mt20{
    margin-top: 20px;
  }
  .info-left {
    width: 46%;display: inline-block;float: left;
    .bottom {
      color: #2f3338;font-size: 13px;line-height: 18px;
      p{
        margin-top: 10px;
      }
      .mark{
        color: #848484;
      }
    }
  }
  .img-area {
    position: relative;
    cursor: pointer;
    .img_bg {
      background-size: cover;
      background-position: center center;
    }
    img{
      vertical-align: middle;
      width: 100%;
    }
    .desc{
      background: rgba(0,0,0,.5);color: #fff;font-size: 14px;position: absolute;
      left: 0;right: 0;bottom: 0;width: 100%;padding: 7px 20px;box-sizing: border-box;
      white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
    }
  }
  .info-right {
    width: 52%;display: inline-block;float: right;
    font-size: 16px;color: #2f3338;line-height: 24px;
    &-list {
      li{
        margin-bottom: 5px;
        cursor: pointer;
      }
      li:hover {
        color: #51d5c3;
      }
      li:before {
        content: '';background: #c6c6c6;border-radius: 50%;margin-right: 10px;
        width: 5px;height: 5px;display: inline-block;vertical-align: middle;
      }
    }
  }
  .part2 {
    .left {
      width: 66%;display: inline-block;float: left;
    }
    .right {
      width: 33%;display: inline-block;float: right;
      .img-area{
        margin-bottom: 10px;
      }
    }
  }
  .h-tabs-content {
    padding: 10px 20px;min-height: 608px;box-sizing: border-box;color: #7a7a7a;
  }
  .hot-list {
    font-size: 15px;
    li {
      padding: 10px 0;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
      border-bottom: 1px solid #dcdcdc;cursor: pointer;
    }
    li:hover {
       color: #51d5c3;
     }
  }
  .t-title {
    .title{
      color: #7a7a7a;font-weight: 600;font-size: 17px;padding-right: 10px;
    }
    .icon {
      width: 22px;height: 22px;display: inline-block;vertical-align: middle;background-size: 100% 100%;
      margin-right: 10px;background-image: url("~@/assets/img/icon_pinglun.png");
    }
  }
  .comments{
    padding: 10px;
    font-size: 13px;
    line-height: 18px;
    .intro-item {
      width: 48.5%;margin-right: 3%;float: left;font-size: 12px; color: #888;
    }
    .intro-item:nth-child(2n) {
      margin-right: 0;
    }
    a:hover {
      color: #51d5c3;
    }
  }
  .comments>div{
    margin: 5px 0;
    cursor: pointer;
  }
  .comments>div:hover{
    color: #51d5c3;
    p:hover{
      color: #51d5c3;
    }
  }
  .part1 .img01{
    height: 225px;
  }
  .part2 .img02{
    height: 290px;
  }
  .part2 .img03{
    height: 140px;
  }
  .link{
    white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
    color: #2f3338;display: block;
  }
  .link:hover{
    color: #51d5c3;
  }
  .intro-item-img{
   width: 100%;
  }
  .imgborder{
    width: 100%;
    height: 100px;
    overflow: hidden;
  }
  .contentImg{
    background: url('../../../assets/img/imgEro.jpg');
  }
</style>
