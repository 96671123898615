<template>
  <div class="h-panel cf">
    <div class="left-container">
      <div class="h-title">
        <span class="title"><i class="icon icon_hangqing"></i>农村电商</span>
      </div>
      <div class="flex list">
        <div class="listmain" v-for="(item,idx) in busiList" :key="idx" @click="jump(item)" v-show="item.isValid==1">
          <div class="listmainImg"><img :src="item.commodityIcon"></div>
         <div class="listmainBottom">
            <h3 class="listmainTitle">{{item.commodityName}}</h3>
            <!-- <div class="flex listaminPrico">
              <div class="flex">
                <h3>￥<span>47.90</span></h3>
                <p>￥57.00</p>
              </div>
              <div>已售：<span>1190</span>件</div>
            </div> -->
         </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getRuralFinanceList, getOrganizationContent, getbusinessList } from '@/api/home2.js';
  export default {
    name: 'business',
    data () {
      return {
        msg: 0,
        ruralList: '',
        organList: '',
        busiList:''
      }
    },
    methods: {
      getrural (cid) {
        getRuralFinanceList({
          columnId: cid
        }).then((res) => {
          this.ruralList=res.data
          const bustype=1
          getOrganizationContent({ bustype }).then((res) => {
            this.organList=res.data.records.slice(0, 6)
          })
        })
      },
      son(item,idx) {
        this.msg=idx
        const bustype=idx+1
        getOrganizationContent({ bustype }).then((res) => {
          this.organList=res.data.records.slice(0, 6)
        })
      },
      getbusi () {
        getbusinessList({}).then((res) => {
          this.busiList=res.data.records
        })
      },
      jump(item){
       if(item.url.slice(0,8)=='https://' || item.url.slice(0,7)=='http://'){
          window.open(item.url,'_blank')
        }else{
           window.open('http://'+item.url,'_blank')
        }
      },
      jumpmoney(item){
        this.$router.push({
          path: '/ruralFinance',
          query: {
           bustype:item.bustype
          }
        })
      }
    },
    mounted () {
      this.getbusi()
    },
    computed: {
      ...mapGetters({
        columnData:'getColumnData'
      })
    },
    watch: {
      columnData (val) {
        if(JSON.stringify(val) !== '{}') {
          this.columId=this.columnData.ruralFinance.id
          // this.getrural(this.columnData.ruralFinance.id)
        }
      },
      immediate: true
    }
  }
</script>

<style scoped>
  .icon_hangqing {
    background-image: url("~@/assets/img/icon_dianshan.png");
  }
  .h-title{
    margin-bottom: 30px;
  }
  .list{
    flex-wrap: wrap;
    justify-content: left;
  }
  .listmain{
    box-shadow: 5px 5px 10px 0px rgba(221, 221, 221,0.8);
    width: 32%;
    border: 1px solid #e9e9e9;
    margin-right: 1%;
    margin-bottom: 15px;
    cursor: pointer;
  }
  .listmainImg{
    width: 100%;
    border: 1px solid #e9e9e9;
  }
  .listmainImg img{
    width: 100%;
  }
  .listmainBottom{
    padding: 0 4%;
  }
  .listmainTitle{
    color: #5f7fe7;
    margin: 10px 0;
  }
  .listaminPrico{
    justify-content: space-between;
    margin: 20px 0;
  }
  .listaminPrico div{
    align-items: center;
  }
  .listaminPrico h3 span{
    color: #ee8240;
  }
  .listaminPrico p{
    text-decoration: line-through;
    color: #c7c7c7;
    margin-left: 10px;
  }
  .right-container{
    width: 100%;
    height: 100%;
  }
  .Tab{
    border: 1px solid #dcdcdc;
  }
  .Tabbtn{
    width: 100%;
    justify-content: space-between;
  }
  .Tabbtn div{
    text-align: center;
    border: 1px solid #dcdcdc;
    background-color: #f8f8f8;
    color: #7a7a7a;
    width: 33%;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
  }
  .Tabbtn .active{
    border: none;
    border-top: 2px solid #51d5c3;
    font-weight: bold;
    font-size: 18px;
  }
  .TabContent>div{
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 10px;
  }
  .TabContent>div>div{
    width: 45%;
    margin: 10px 0;
  }
  .TabContent>div>div img{
    width: 100%;
  }
  .TabBanner{
    margin: 10px 0;
  }
  .TabBanner img{
    width: 100%;
    height: 130px;
  }
  .TabBottom{
    padding: 10px 5%;
    border-bottom: 2px solid #cfcfcf;
    position: relative;
  }
  .TabBottom>div:nth-child(2){
    margin-left: 20px;
  }
  .TabBottom h1{
    margin-top: 30px;
    color: #0d62b3;
    font-weight: bold;
  }
  .TabBottom p{
    margin-top: 20px;
    color: #868686;
  }
  .TabBottom::before{
    content: "";
    width: 1px;
    height: 20px;
    border: 2px solid transparent;
    border-left: 2px solid #cfcfcf;
    transform: rotate(-7deg);
    position: absolute;
    bottom: -1px;
    left: -1px;
  }
  .TabBottom::after{
    content: "";
    width: 1px;
    height: 20px;
    border: 2px solid transparent;
    border-right: 2px solid #cfcfcf;
    transform: rotate(7deg);
    position: absolute;
    bottom: -1px;
    right: -1px;
  }
  </style>
